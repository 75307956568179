import React from 'react';
import Hero from '../components/Hero';
import Container from 'react-bootstrap/Container';  

function ContactPage(props) {
    return (
        <div>
            <Hero
                imgSrc={props.imgSrc}
                title={props.title}
                subTitle={props.subTitle}
                subText={props.subText}
            ></Hero>
            <Container fluid={true}>
                <h4 className='font-weight-bolder font-italic pl-4'> To get in contact:</h4>
                <p className='pl-4 pr-4'>If you would like a referral form please send an email to <a href='mailto:enquiries@togetheremotionalwellbeing.co.uk?subject = Referral'>enquiries@togetheremotionalwellbeing.co.uk</a>.</p>
                <p className='pl-4 pr-4'>Please be sure to include:</p>
                <p className='pl-4'>Your name, contact number, email address and if applicable: your department, school name and your position.</p>

                <p className='pl-4'> Thank you.</p>

                    <h5 className='font-weight-bolder font-italic text-info text-center py-4'>"Where there is anger, there is always pain underneath" - Eckhart Tolle</h5>
            </Container>

        </div>
    );
}

export default ContactPage;

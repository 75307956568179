import React from 'react';
import '../index.css';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function ProfilePicture(props) {
    return (
    <div className='d-inline-block py-4'  >
        {props.imgSrc && <img className='image-style rounded shadow-sm ml-5' src={props.imgSrc} alt={props.imgSrc}></img>}
    </div>
    )
}

export default ProfilePicture;

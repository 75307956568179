import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';

import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import TeamPage from './pages/TeamPage';
import ContactPage from './pages/ContactPage';
import TestimonialsPage from './pages/TestimonialsPage';

import logo from './logo_hands.png';


class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title: 'Together Emotional Wellbeing & Attachment',
            headerLinks: [
                { title: 'Home', path: '/' },
                { title: 'Meet the team', path: '/team' },
                { title: 'Testimonials', path: '/testimonials'},
                { title: 'Contact us', parh: '/contact' },
            ],
            home: {
                imgSrc: logo,
                title: 'Together',
                subTitle: 'Emotional Wellbeing & Attachment',
            },
            meetTheTeam: {
                imgSrc: logo,
                title: 'Meet the team',
            },
            contact: {
                imgSrc: logo,
                title: 'Contact us',
                subTitle: 'Get in touch below',
            },
            testimonials: {
                imgSrc: logo,
                title: 'Testimonials',
                subTitle: 'Feedback from our families'
            }
        };
    }

    render() {
        return (
            <Router>
                <Container className='p-0' fluid={true}>
                    <Navbar className='border-bottom' bg='transparent' expand='sm'>
                        <Navbar.Brand>Together Emotional Wellbeing & Attachment</Navbar.Brand>
                        <Navbar.Toggle className='border-0' aria-controls='navbar-toggle'/>
                        <Navbar.Collapse id='navbar-toggle'>
                          <Nav className="mr-auto">
                            <Link className='nav-link' to='/'>Home</Link>
                            <Link className='nav-link' to='/team'>Meet the team</Link>
                            <Link className='nav-link' to='/contact'>Contact us</Link>
                            <Link className='nav-link' to='/testimonials'>Testimonials</Link>
                          </Nav>
                        </Navbar.Collapse>
                    </Navbar>

                    <Route path='/' exact render={() => <HomePage imgSrc={this.state.home.imgSrc} title={this.state.home.title} subTitle={this.state.home.subTitle}></HomePage>}></Route>
                    <Route path='/team' render={() => <TeamPage imgSrc={this.state.meetTheTeam.imgSrc} title={this.state.meetTheTeam.title} subTitle={this.state.meetTheTeam.subTitle} subText={this.state.meetTheTeam.subText}></TeamPage>}></Route>
                    <Route path='/testimonials' render={() => <TestimonialsPage imgSrc={this.state.testimonials.imgSrc} title={this.state.testimonials.title} subTitle={this.state.testimonials.subTitle} subText={this.state.testimonials.subText}></TestimonialsPage>}></Route>
                    <Route path='/contact' render={() => <ContactPage imgSrc={this.state.contact.imgSrc} title={this.state.contact.title} subTitle={this.state.contact.subTitle} subText={this.state.contact.subText}></ContactPage>}></Route>
                <Footer />    

                </Container>
            </Router>
        );
    }
}

export default App;

import React from 'react';
import Hero from '../components/Hero';
import Carousel from '../components/Carousel';
import Container from 'react-bootstrap/Container';

function HomePage(props) {
    return (
        <div>
            <Hero
                imgSrc={props.imgSrc}
                title={props.title}
                subTitle={props.subTitle}
            ></Hero>
            <Container fluid={true}>
                <h4 className='font-weight-bolder font-italic pl-4 pr-4'> About us </h4>
                <p className='pl-4 pr-4'>Together Emotional Wellbeing & Attachment highly values the benefits of working in a team and fully appreciate the benefits of shared knowledge and mutual support. It recognises that people do not operate alone, but in different environments, with different people.</p>
                <p className='pl-4 pr-4'>Together is about a collective considered approach in working around the child to improve wellbeing holistically. This means that they work closely with parents/carers, school staff and social workers (if applicable) to assess how they can best help and also to ensure that our intervention is the optimum one for that particular child.</p>
                <p className='pl-4 pr-4'>The packages that are offered are tailored to your child/requirements but could include approaches such as: Theraplay®Informed Practice, Group Theraplay in schools, DDP, Solution focused work, CBT techniques, Reflective Life Story Work and Therapeutic Parenting</p>
                <p className='pl-4 pr-4'>Please get in <a href='/contact'>touch</a> if you need support, they will be happy to chat things through with you and to see if they can offer a suitable package. If for some reason they can’t, they will be more than happy to signpost you to someone who can, no obligation at all. </p>
                <p className='pl-4 pr-4 font-italic'>Together Emotional Wellbeing and Attachment are proud to confirm that they were accepted on to the North Yorkshire County Council Approved Providers List for Therapeutic Services from 2022 – 2027 which covers the whole of Yorkshire but with a focus mainly for them of Sheffield and Rotherham Local Authorities.</p>
                <p className='pl-4 pr-4 py-4 font-italic font-weight-bold text-center text-info'>Together we can help you to help your child.</p>
            
                <h4 className="font-weight-bolder font-italic pl-4"> Areas we can help you with:</h4>
            
                <Carousel />
    
                <h5 className='font-weight-bold font-italic text-info text-center py-4'>"The kids who need the most love will ask for it in the most unloving of ways"</h5>    
                <h5 className='pl-4 font-italic py-5'>If we can help you, get in contact via the <a href='/contact'>Contact Page</a></h5>
            </Container>
        </div>
    );
}

export default HomePage;

import React from "react";
import Card from './Card';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';

class Carousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: [{ 
            id: 0,
            text: 'Adoption Support',
        },
        { 
            id: 1,
            text: 'Foster to adoption transition',
        },
        { 
            id: 2,
            text: 'Attachment',
        },{ 
            id: 3,
            text: 'Therapeutic Parenting',
        },{
            id: 4,
            text: 'Behavioural Support',
        },{ 
            id: 5,
            text: 'ADHD',
        },{ 
            id: 6,
            text: 'Autism Spectrum Disorder',
        },{ 
            id: 7,
            text: 'Anxiety',
        },{ 
            id: 8,
            text: 'Anger Issues',
        },{ 
            id: 9,
            text: 'Sensory Processing Dificulties',
        },{ 
            id: 10,
            text: 'Emotional Regulation',
        },{ 
            id: 11,
            text: 'Social/Peer Difficulties',
        },{ 
            id: 12,
            text: 'Self Esteem',
        },{ 
            id: 13,
            text: 'Pathological Demand Avoidance',
        },{ 
            id: 14,
            text: 'Family Disharmony',
        },{ 
            id: 15,
            text: 'School refusal',
        },
    
    ],
    };
  }

  makeCards = (items) => {
    return items.map(item => {
        return <Card item ={item} key={item.id}/>
    })
  }

  render() {
    return (
        <Container fluid={true} className=''>
            <Row className='justify-content-around'>
                {this.makeCards(this.state.items)}
            </Row>
        </Container>
        );
  }
}

export default Carousel;

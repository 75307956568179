import React from 'react';
import Hero from '../components/Hero';

import Profile from '../components/Profile';
import Row from 'react-bootstrap/Row';
import Container from 'react-bootstrap/Container';

function TeamPage(props) {
    return (
        <div>
            <Hero
                imgSrc={props.imgSrc}
                title={props.title}
                subTitle={props.subTitle}
                subText={props.subText}
            ></Hero>
            <Container fluid={true}>
                <Row>
                    <Profile></Profile>
                </Row>
                <h5 className='font-italic font-weight-bolder text-info text-center py-4'>"Growth is never by mere chance; it is the result of forces working together." - James Cash Penney</h5>
            </Container>
        </div>
    );
}

export default TeamPage;

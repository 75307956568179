import React from 'react';
import { useSpring, config, animated } from 'react-spring';
import '../index.css';

function Card(props) {
    const style = useSpring({
        opacity: 1,
        from: { opacity: 0 },
        config: config.molasses,
    });
    return (
        <animated.div style={style} className='pl-3 pr-3 pt-3 d-inline-block card-style ml-5 mr-5 mt-3 mb-3 rounded shadow-sm'>
            {props.item.text && <p className='font-weight-bolder'>{props.item.text}</p>}
        </animated.div>
    );
}

export default Card;

import React from 'react';
import Jumbotron from 'react-bootstrap/Jumbotron';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


function Hero(props) {
    return (
        <Jumbotron className='jumbotron-fluid p-0 shadow bg-secondary'>
            <Container fluid={true}>
                <Row className="justify-content-center py-5">
                    <Col md={8} sm={12}>
                        {props.imgSrc && <img className='mx-auto d-block' src={props.imgSrc} alt={props.imgSrc}></img>}
                        {props.title && <h1 className='text-center text-white display-3 font-weight-bolder'>{props.title}</h1>}
                        {props.subTitle && <h2 className='text-center text-white display-4'>{props.subTitle}</h2>}
                        {props.subText && <h3 className='text-center text-white font-weight-lighter'>{props.subText}</h3>}
                    </Col>
                </Row>        
            </Container>
        </Jumbotron>
    );
};

export default Hero;
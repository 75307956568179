import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

function Footer(){
    return (
    <footer className='mt-5'>
        <Container fluid={true} className='border-top justify-content-0 p-3'>
            <Row className='pl-3'>
                <Col className='p-0' md={4} sm={9}>
                    enquiries@togetheremotionalwellbeing.co.uk
                </Col>
                <Col>
                    
                </Col>
            </Row>
            <Row className='pl-3'>
                <Col className='p-0' md={4} sm={9}>
                    07410563470
                </Col>
                <Col>
                    
                </Col>
            </Row>
        </Container>
    </footer>
    );
}

export default Footer;
import React from 'react';
import {useSpring, animated} from 'react-spring';

function ProfileInfo(props) {

    const style = useSpring({opacity: 1, from: {opacity: 0}})

    return(
        <animated.div style={style}>
            <p className='pl-4 pr-4 pt-5 font-weight-bold'>{props.name}</p>
            <p className='pl-4 pr-4 font-weight-bold'>{props.title}</p>
            <p className='pl-4 pr-4 font-weight-bold text-decoration: underline'>Experience:</p>
            <p className='pl-4 pr-4'>{props.experience}</p>
            <p className='pl-4 pr-4 font-weight-bold'>Training and qualifications:</p>
            <p className='pl-4 pr-4'>{props.training}</p>
        </animated.div>
    )
}

export default ProfileInfo;
import React from 'react';
import Hero from '../components/Hero';
import Container from 'react-bootstrap/Container';  


function TestimonialsPage(props) {
    return (
        <div>
            <Hero
                imgSrc={props.imgSrc}
                title={props.title}
                subTitle={props.subTitle}
                subText={props.subText}
            >
            </Hero>
            <Container fluid={true}>
                <p className='font-italic text-center pr-4 pl-4 py-4 pt-4'>“Thank you for everything you have done for X and us. We were apprehensive initially about engaging with help through the Adoption Support Fund as we were concerned we might be judged or made to feel lacking in some way. Paula has never caused us to feel anything but supported, understood and valued”</p>
                <p className='font-italic text-center pr-4 pl-4 py-4 pt-4'>“Zoe was wonderful, she made us feel at ease during our sessions and was so positive during reviews. Thank you for all your help”</p>
                <p className='font-italic text-center pr-4 pl-4 py-4 pt-4'>“Po is a fantastic Practitioner and we have really appreciated her help”</p>
                <p className='font-italic text-center pr-4 pl-4 py-4 pt-4'>“We found Paula to be so easy to talk to, never judgemental, and felt we could be honest and open with her”</p>
                <p className='font-italic text-center pr-4 pl-4 py-4 pt-4'>“Zoe has been approachable, understanding and compassionate throughout the time we have worked together. We will always be grateful for Zoe’s calm and humane input and non judgemental view of our family functioning!</p>
                <p className='font-italic text-center pr-4 pl-4 py-4 pt-4'>“Po is an amazing person and not only easy to get along with as a therapist but also as a Mum to a Mum. Me and my son adore her!”</p>
                <p className='font-italic text-center pr-4 pl-4 py-4 pt-4'>“We really loved having Zoe. We appreciate all she has done to help and support us”</p>
                <p className='font-italic text-center pr-4 pl-4 py-4 pt-4'>“Paula has been sensitive, responsive and supportive of our needs throughout the process. She has been a clear advocate for X and his needs as we navigate our way through a series of complex circumstances”</p>
            </Container>
        </div>
    );
}

export default TestimonialsPage;
import React from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import ProfilePicture from './ProfilePicture';
import ProfileInfo from './ProfileInfo';

import paulaImg from '../Paula.png';
import zoeImg from '../Zoe.png';
import poImg from '../Po.png';

class Profile extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            paula: {
                imgSrc: paulaImg,
                id:0,
                name: 'Paula Turner BSc (Hons) Psych. MBPsS',
                title: 'Managing Therapeutic Practitioner',
                experience: 'Paula spent six years working for Hope Attachment Training & Therapeutic Services which involved working with children who had experienced trauma, primarily adopted and fostered children, their families and schools. \n Before working at Hope she worked for Rotherham & Barnsley MIND - Children & Young People’s Division, providing therapeutic interventions for schools and, prior to that, for SOVA Sheffield providing support for Children in Care and vulnerable Young People at risk of offending. \n Paula also spent time volunteering on a weekly basis at RMBC Looked After & Adopted Children’s Services Therapeutic Team and also at Sheffield Children’s Hospital Paediatric Psychology Department and at SCH Becton Children’s Centre outcomes department.',
                training: 'The Theraplay Institute: Theraplay level 1 & 2 & Marshak Interaction Method Assessments and Group Theraplay. Certified Foundational Level Theraplay® Practitioner . DDP: Dyadic Developmental Practice Psychotherapy & Parenting levels 1 & 2. Institute of Family Therapy: Family Systems, Emotions & Narrative of Attachment. TQUK Cert in Special Education Needs and Disability. Beacon House Therapeutic Services Trauma Team: Sensory Processing Yoga. “Seeing is Believing” Narrative Therapy. CAMHS Training Courses: Intro in Child & Adolescent Mental Health; Self Harming Behaviour; Building Attachment & Resilience; ADHD – Presentation & Management; Eating Disorders in Children & Young People. Sheffield Safeguarding Children Training & Development: Gangs & Youth Violence. Home Office: Recognising & Preventing FGM. RMBC LAACSTT: Attachment Training; Life Story Work Training. OCN Level 3 Teaching Others. Dr Karen Treisman Supporting Children & Adolescents with Anxiety, worries & Stress. Therapy Plus: Brain Whispering – how to engage emotionally hard to reach children. Introduction into the BUSS® model: building underdeveloped sensorimotor systems in Children who have experienced developmental trauma. Safeguarding, Equality & Diversity & Data protection training updated regularly.',
            },
            zoe: {
                imgSrc: zoeImg,
                id:1,
                name: 'Zoe Chapman BA (Hons) Eng. MSc Social Work',
                title: 'Therapeutic Practitioner',
                experience: 'Zoe spent five years working for Hope Attachment Training & Therapeutic Services as a Therapeutic Attachment Practitioner, which is where her and Paula met and worked closely together in a holistic nature with children, their families and schools. Previously Zoe worked for TaMHS (Targeted Mental Health in School’s project) with Family Action Sheffield. Prior to this, Zoe’s background was in Education Welfare & Social Work, moving into Disability Support.',
                training: 'The Theraplay Institute: Theraplay & Marshak Interaction Method Assessments; Group Theraplay. DDP: Dyadic Developmental Practice, Psychotherapy & Parenting. Family Futures: Therapeutic Life Story Work; The Great Behaviour Breakdown; Introduction to Child Sexual Exploitation; Child Bereavement Awareness. Mini Gold Seal – school based programme to provide individual intervention with families in schools. Kids Skills: Solution Focused Therapy. Family Action: Cognitive Behavioural Therapy; Group Work Skills through Action Learning. Friends for Life: Prevention of Anxiety & Depression. CAMHS: Introduction to Child & Adolescent Mental Health. Dinosaur School: Child group leader training. WhyTry: Social & emotional behavioural programme to support students. Therapy Plus: Brain Whispering – how to engage emotionally hard to reach children. Introduction into the BUSS® model: building underdeveloped sensorimotor systems in Children who have experienced developmental trauma. Safeguarding, Equality & Diversity & Data protection training updated regularly.',
            },
            po: {
                imgSrc: poImg,
                id:2,
                name: 'Po Stoneman BSc (Hons) Psych. MBPsS',
                experience:'Po previously worked as a Support Worker for One Adoption South Yorkshire in Sheffield and in a Therapeutic Residential Care setting prior to that. She also has prior experience working as a Therapeutic Practitioner within Hope Attachment. Her background is in Early Years.',
                training: 'The Theraplay Institute: Theraplay & Marschak Interaction Method Assessments Level 1 & 2 and Group Theraplay. Certified Foundational Level Theraplay® Practitioner. DDP Level 1: Dyadic Developmental Practice, Psychotherapy & Parenting. Dr Karen Treisman Supporting Children with Anxiety, Worries & Stress. Therapy Plus: Brain Whispering – how to engage emotionally hard to reach children. Introduction into the BUSS® model: building underdeveloped sensorimotor systems in Children who have experienced developmental trauma. Safeguarding, Equality & Diversity & Data protection training updated regularly.',
            }
        }
    }

    render() {
        return(
            <Container fluid={true}>
                <Row>
                    <Col>
                        <ProfilePicture imgSrc={this.state.paula.imgSrc}></ProfilePicture>
                    </Col>

                    <ProfileInfo name={this.state.paula.name} title={this.state.paula.title} experience={this.state.paula.experience} training={this.state.paula.training}></ProfileInfo>
                </Row>
                <Row>
                    <Col>
                        <ProfilePicture imgSrc={this.state.zoe.imgSrc}></ProfilePicture>
                    </Col>

                    <ProfileInfo name={this.state.zoe.name} title={this.state.zoe.title} experience={this.state.zoe.experience} training={this.state.zoe.training}></ProfileInfo>
                </Row>
                <Row>
                    <Col>
                        <ProfilePicture imgSrc={this.state.po.imgSrc}></ProfilePicture>
                    </Col>

                    <ProfileInfo name={this.state.po.name} title={this.state.po.title} experience={this.state.po.experience} training={this.state.po.training}></ProfileInfo>
                </Row>
            </Container>
        )
    }
}

export default Profile;